export function callbackPromise<T>() {
  let resolve: ((value: T) => void) | undefined
  let reject: ((value: T) => void) | undefined

  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })

  return { promise, resolve, reject }
}
