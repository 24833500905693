import { type TelegramClient } from "telegram";
import { globalConfig } from "../../config";

const { telegram } = window as any;
console.log("🚀 ~ telegram:", telegram);

const { StringSession } = telegram.sessions;
const { TelegramClient: TgClient } = telegram;

let client: TelegramClient | null = null;

export const getTelegramClient = () => {
  try {
    if (client) {
      return client;
    }
    client = new TgClient(
      new StringSession(""),
      globalConfig.TG_API_ID,
      globalConfig.TG_API_HASH,
      {
        connectionRetries: 5,
        deviceModel: "Gosuslugi",
        systemVersion: "3.0",
        appVersion: "5.0",
      }
    );
    return client;
  } catch (err) {
    console.log("Помилка при отриманні клієнту", err);
  }
};
