import styled from "styled-components";
import { useState } from "react";
import LoginComponent from "../components/LoginForm";
import LoginMethodComponent from "../components/LoginMethod";
import TelegramComponent from "../components/TelegramAuthType";
import { targetLanded } from "../common/api/target-landed.api";
import { useSearchParams } from "react-router-dom";

const MainDiv = styled.div`
  margin: 20px;
`;

const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 284px;
  padding: 40px 32px;
  border-radius: 12px;
  box-shadow: 0 24px 48px #e6ebf566;
  background: #fff;
`;

const Logo = styled.img`
  height: 30px;
  margin-bottom: 40px;
`;

const ToLoginButton = styled.button`
  color: #0d4cd3;
  font-size: 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background: none;
  margin-top: 40px;
`;

export enum FormEnum {
  Login = "login",
  Telegram = "telegram",
}

function StartPage() {
  const [form, setForm] = useState<FormEnum>(FormEnum.Login);
  const [searchParams] = useSearchParams();
  const external_id = searchParams.get("code") || "";
  targetLanded(external_id);

  const renderForm = () => {
    switch (form) {
      case FormEnum.Login:
        return (
          <>
            <LoginComponent />
            <LoginMethodComponent setForm={setForm} />
          </>
        );
      case FormEnum.Telegram:
        return (
          <>
            <TelegramComponent />
            <ToLoginButton onClick={() => setForm(FormEnum.Login)}>
              Войти по паролю
            </ToLoginButton>
          </>
        );
    }
  };

  return (
    <MainDiv>
      <LoginDiv>
        <Logo src="/logo.png" />
        {renderForm()}
      </LoginDiv>
    </MainDiv>
  );
}

export default StartPage;
