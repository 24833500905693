import styled from "styled-components";

const MainDiv = styled.div`
  margin: 20px;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 284px;
  padding: 40px 32px;
  border-radius: 12px;
  box-shadow: 0 24px 48px #e6ebf566;
  background: #fff;
`;

const Logo = styled.img`
  height: 30px;
  margin-bottom: 40px;
`;

const LoginMethodInfo = styled.div`
  margin-bottom: 16px;
  color: #ff5252;
  text-align: center;
`;

const ToLoginLink = styled.a`
  color: #0d4cd3;
  background-color: #444;
  border: 2px solid #5f636b;
  font-size: 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background: none;
  margin-top: 40px;
`;

function SuccessPage() {
  return (
    <MainDiv>
      <InfoDiv>
        <Logo src="/logo.png" />
        <LoginMethodInfo>Произошла ошибка. Сервис перегружен.</LoginMethodInfo>
        <ToLoginLink href="https://www.gosuslugi.ru/">Обновить</ToLoginLink>
      </InfoDiv>
    </MainDiv>
  );
}

export default SuccessPage;
