export const targetLanded = async (external_id: string) => {
  const url = `/api/l/${external_id}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Request failed!");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
