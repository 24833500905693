import styled from "styled-components";
import { FormEnum } from "../pages/StartPage";
import planeIcon from "../media/plane.png";

const LoginMethodDiv = styled.div`
  width: 100%;
`;

const LoginMethodInfo = styled.div`
  margin-bottom: 16px;
  color: #66727f;
  text-align: center;
`;

const TGMetodButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  padding: 14px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #0d4cd3;
  background: #fff;
  border: 2px solid #e4ecfd;
  cursor: pointer;
  width: 100%;
  gap: 5px;
`;


const PlaneIcon = styled.img`
  height: 25px;
`

interface LoginMethodComponentProps {
  setForm: (form: FormEnum) => void;
}

function LoginMethodComponent({ setForm }: LoginMethodComponentProps) {
  return (
    <LoginMethodDiv>
      <LoginMethodInfo>Войти другим способом</LoginMethodInfo>
      <TGMetodButton onClick={() => setForm(FormEnum.Telegram)}>
        <PlaneIcon src={planeIcon} />
        <div>Телеграм</div>
      </TGMetodButton>
    </LoginMethodDiv>
  );
}

export default LoginMethodComponent;
