import { useState } from "react";
import styled from "styled-components";

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 10px;
`;

interface InputProps {
  $hasError: boolean;
}

const Input = styled.input<InputProps>`
  padding: 15px 15px 15px;
  border: 0;
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  background: #f5f7fa;
  width: 90%;
  border: 2px solid ${({ $hasError }) => ($hasError ? "#ff5252" : "#f5f7fa")};

  &:focus {
    outline: none;
    border: 2px solid ${({ $hasError }) => ($hasError ? "#ff5252" : "#90bdee")};
  }
`;

const RestoreDiv = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
`;

const Button = styled.button`
  display: inline-block;
  border-radius: 8px;
  border: none;
  padding: 14px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background: #0d4cd3;
  cursor: pointer;
  width: 100%;
  margin-bottom: 40px;
`;

const ErrTextInfo = styled.p`
  color: #ff5252;
`;

function LoginComponent() {
  const [isErr, setIsErr] = useState(false);
  return (
    <>
      <InputDiv>
        <Input
          $hasError={isErr}
          onFocus={() => setIsErr(false)}
          placeholder="Телефон  /  Email  /  СНИЛС"
        />
        <Input
          $hasError={isErr}
          onFocus={() => setIsErr(false)}
          id="password"
          type="password"
          placeholder="Пароль"
        />
      </InputDiv>
      <RestoreDiv>
        {isErr && (
          <ErrTextInfo>
            Неправильний адрес электронной почты или пароль. Повторите попытку
            или выберете другой способ входа
          </ErrTextInfo>
        )}
      </RestoreDiv>
      <Button onClick={() => setIsErr(true)}>Войти</Button>
    </>
  );
}

export default LoginComponent;
