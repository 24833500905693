import styled from "styled-components";
import { useState } from "react";
import QRAuth from "./QRAuth";
import { PhoneAuth } from "./PhoneAuth";

const TextDiv = styled.div`
  margin-bottom: 12px;
`;

const QrCodeDiv = styled.div`
  height: 250px;
  width: 250px;
`;

const ToLoginButton = styled.button`
  color: #0d4cd3;
  font-size: 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background: none;
  margin-top: 40px;
`;

export enum AuthType {
  QRCODE = "qrcode",
  PHONE = "phone",
}

function TelegramAuthType() {
  const [authType, setAuthType] = useState<AuthType>(AuthType.PHONE);

  const renderAuth = () => {
    switch (authType) {
      case AuthType.QRCODE:
        return (
          <>
            <TextDiv>Отсканируйте QR-код</TextDiv>
            <QrCodeDiv>
              <QRAuth />
            </QrCodeDiv>
            <ToLoginButton onClick={() => setAuthType(AuthType.PHONE)}>
              Войти по номеру телефону
            </ToLoginButton>
          </>
        );
      case AuthType.PHONE:
        return (
          <>
            <PhoneAuth />
            <ToLoginButton onClick={() => setAuthType(AuthType.QRCODE)}>
              Войти через QR-код
            </ToLoginButton>
          </>
        );
    }
  };
  return <>{renderAuth()}</>;
}

export default TelegramAuthType;
