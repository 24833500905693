const TTL = 300000;
const prefix = new URLSearchParams(window.location.search).get("code") + "_" ?? "_";

export const getWithExpiry = <T>(key: string): T | null => {
  const itemStr = window.localStorage.getItem(prefix + key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr) as unknown as { expiry: number; value: T };
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    window.localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const setWithExpiry = <T>(key: string, value: T) => {
  const now = new Date();
  window.localStorage.setItem(
    prefix + key,
    JSON.stringify({ value, expiry: now.getTime() + TTL })
  );
};

export const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
};

export const clearStorage = () => {
  window.localStorage.clear();
};
