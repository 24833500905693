import MTProto, {
  type InputPeer,
  type chatPhoto,
  type messages_dialogsSlice,
  type peerChannel,
  type peerChat,
  type peerUser,
  type userProfilePhoto,
  type account_password,
  type auth_SentCode,
  type user,
} from "@mtproto/core/envs/browser";
import { getWithExpiry, setWithExpiry } from "./storage";

interface MTProtoRPCError {
  error_code: number;
  error_message: string;
  _: "mt_rpc_error";
}

export interface Dialog {
  id: number;
  type: string;
  title: string;
  lastMessage: string;
  photo: userProfilePhoto | chatPhoto;
  photoURL?: string;
  inputPeer: InputPeer;
}

export const isMTProtoError = (error: unknown): error is MTProtoRPCError => {
  return error instanceof Object && "_" in error && error._ === "mt_rpc_error";
};

export const isMTProtoUser = (input: unknown): input is user => {
  return input instanceof Object && "_" in input && input._ === "user";
};

export type MTProtoUser = user;
export type MTProtoPasswordRequired = account_password;

export class TgClient {
  private mtproto: MTProto;
  private dc: number;

  constructor(apiId: number, apiHash: string, useTestMode = false, dc = 2) {
    console.log(`setup mtproto in ${useTestMode ? "test" : "production"} mode`);

    this.mtproto = new MTProto({
      api_id: apiId,
      api_hash: apiHash,
      test: useTestMode,
      storageOptions: {
        instance: {
          get: (key: string) => {
            return Promise.resolve(getWithExpiry<string>(key) ?? null);
          },
          set: (key: string, value: string) => {
            setWithExpiry(key, value);
            return Promise.resolve();
          },
        },
      },
    });

    this.dc = dc;
  }

  getSessionString() {
    const dcId = this.dc;

    const authKey = new Uint8Array(
      JSON.parse(getWithExpiry<string>(`${dcId}authKey`) ?? "")
    );

    const dc: {
      id: number;
      ip: string;
      port: number;
      //@ts-ignore
    } = this.mtproto.getRPC(dcId).dc;

    Array(dc.ip.length)
      .fill(0)
      .map((_, index) => dc.ip.charCodeAt(index));

    const serverAddress = new Uint8Array(
      Array(dc.ip.length)
        .fill(0)
        .map((_, index) => dc.ip.charCodeAt(index))
    );

    // Calculate the total length of the buffer
    const totalLength = 1 + 2 + serverAddress.length + 2 + authKey.length;
    const buffer = new Uint8Array(totalLength);

    // Create a DataView for convenient value setting
    const view = new DataView(buffer.buffer);

    // Set dcId (1 byte)
    buffer[0] = dcId;

    // Set address length (2 bytes)
    view.setUint16(1, serverAddress.length, false); // Big endian

    // Set address (variable length)
    buffer.set(serverAddress, 3);

    // Set port (2 bytes)
    view.setUint16(3 + serverAddress.length, dc.port, false); // Big endian

    // Set key (variable length)
    buffer.set(authKey, 5 + serverAddress.length);

    // Convert to base64 and prepend "1"
    return "1" + btoa(String.fromCharCode.apply(null, Array.from(buffer)));
  }

  async connect() {
    await this.mtproto.setDefaultDc(this.dc);

    const config = await this.mtproto.call("help.getConfig");

    this.dc = config.this_dc;
    return this;
  }

  async sendPhoneNumber(phoneNumber: string) {
    console.log("sendPhoneNumber");
    console.log(phoneNumber)

    const result = (await this.mtproto.call("auth.sendCode", {
      phone_number: phoneNumber,
      settings: {
        _: "codeSettings",
      },
    })) as auth_SentCode;

    return {
      phoneCodeHash: result.phone_code_hash,
    };
  }

  async sendPhoneCode(
    phoneNumber: string,
    phoneCode: string,
    phoneCodeHash: string
  ): Promise<MTProtoUser | MTProtoPasswordRequired> {
    console.log("sendPhoneCode");
    try {
      const signInResult = await this.mtproto.call("auth.signIn", {
        phone_code: phoneCode,
        phone_number: phoneNumber,
        phone_code_hash: phoneCodeHash,
      });

      if (signInResult._ === "auth.authorizationSignUpRequired") {
        throw new Error("Authorization SignUp Required");
      }

      const { user } = signInResult;

      if (user._ === "userEmpty") {
        throw new Error("User is empty");
      }

      return user;
    } catch (err: unknown) {
      if (
        isMTProtoError(err) &&
        err.error_message === "SESSION_PASSWORD_NEEDED"
      ) {
        return await this.mtproto.call("account.getPassword");
      }

      throw err;
    }

    throw new Error("Something went wrong");
  }

  async sendPasswordRequest(): Promise<MTProtoPasswordRequired> {
    return this.mtproto.call("account.getPassword");
  }

  async sendPassword(
    password: string,
    passwordData: MTProtoPasswordRequired
  ): Promise<MTProtoUser> {
    const { srp_id, current_algo, srp_B } = passwordData;

    if (current_algo._ === "passwordKdfAlgoUnknown") {
      throw new Error("Can't parse password algo");
    }

    const { g, p, salt1, salt2 } = current_algo;

    const { A, M1 } = await this.mtproto.crypto.getSRPParams({
      g,
      p,
      salt1,
      salt2,
      gB: srp_B,
      password,
    });

    const checkPasswordResult = await this.mtproto.call("auth.checkPassword", {
      password: {
        _: "inputCheckPasswordSRP",
        srp_id,
        A,
        M1,
      },
    });

    if (checkPasswordResult._ !== "auth.authorization") {
      throw new Error("Incorrect auth result");
    }

    const { user } = checkPasswordResult;

    if (!isMTProtoUser(user)) {
      throw new Error("Incorrect User");
    }

    return user;
  }

  async getChats() {
    try {
      const response = (await this.mtproto.call("messages.getDialogs", {
        limit: 10,
        offset_peer: {
          _: "inputPeerEmpty",
        },
      })) as messages_dialogsSlice;

      const dialogs: Dialog[] = response.dialogs
        .map((dialog) => {
          const messageObj = response.messages.find(
            (m) => m.id === dialog.top_message
          );
          const message = messageObj?._ === "message" ? messageObj.message : "";
          let title = "";

          if (dialog.peer._ === "peerUser") {
            // let avatar = '';

            const user = response.users.find(
              (u) => u.id === (dialog.peer as peerUser).user_id
            );
            if (!user || user._ === "userEmpty") {
              return null;
            }
            if (user && user._ === "user") {
              title = `${user.first_name ?? ""} ${user.last_name ?? ""}`.trim();
            }

            const inputPeer = {
              _: "inputPeerUser",
              user_id: user.id,
              access_hash: user.access_hash,
            };
            return {
              id: dialog.peer.user_id,
              type: dialog.peer._,
              title: title,
              lastMessage: message,
              photo: user.photo,
              inputPeer,
            };
          }

          if (dialog.peer._ === "peerChannel" || dialog.peer._ === "peerChat") {
            const chat = response.chats.find(
              (c) =>
                c.id === (dialog.peer as peerChat).chat_id ||
                c.id === (dialog.peer as peerChannel).channel_id
            );

            if (
              !chat ||
              chat._ === "channelForbidden" ||
              chat._ === "chatForbidden" ||
              chat._ === "chatEmpty"
            ) {
              return null;
            }

            let inputPeer;

            if (chat._ === "channel") {
              inputPeer = {
                _: "inputPeerChannel",
                channel_id: chat.id,
                access_hash: chat.access_hash,
              };
            } else if (chat._ === "chat") {
              inputPeer = {
                _: "inputPeerChat",
                chat_id: chat.id,
              };
            }

            return {
              id: chat.id,
              type: dialog.peer._,
              title: (chat as any).title || "",
              lastMessage: message,
              photo: chat.photo,
              inputPeer,
            };
          }
        })
        .filter((dialog) => dialog !== null) as Dialog[];

      return dialogs;
    } catch (error) {
      console.error("Failed to fetch dialogs:", error);
      return [];
    }
  }

  async downloadProfilePhoto(inputPeer: InputPeer, photoId: number) {
    try {
      return this.mtproto.call("upload.getFile", {
        location: {
          _: "inputPeerPhotoFileLocation",
          peer: inputPeer,
          big: false,
          photo_id: photoId,
        },
        offset: 0,
        limit: 512 * 1024,
      });
    } catch (error) {
      console.error("Failed to fetch photo:", error);
      return null;
    }
  }

  async disconnect() {
    //hack for remove connection from app
    await Promise.allSettled(
      //@ts-ignore
      Array.from(this.mtproto.rpcs.values()).map((rpc) => {
        //@ts-ignore
        rpc.transport.socket.connect = () => {
          console.warn("ignore socket connect");
        };
        //@ts-ignore
        return rpc.transport.socket.close();
      })
    );
  }
}
